import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from './empty-state';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import LoginButton from '../../../app/components/login-button';

const Unauthenticated = ({ t }) => (
  <EmptyState
    title={t('recent-posts-widget.empty-state.unauthenticated.title')}
    content={t('recent-posts-widget.empty-state.unauthenticated.content')}
    button={<LoginButton />}
  />
);

Unauthenticated.propTypes = {
  t: PropTypes.func,
};

export default withTranslate(Unauthenticated);
